import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import * as firebaseApp from 'firebase/app';
import * as geofirex from 'geofirex';


@Injectable()
export class GeoService {

  geo = geofirex.init(firebaseApp);
  radius = new BehaviorSubject(1.0);

  constructor(private afs: AngularFirestore) {
   }

   getPoint(lat: any, long: any) {
     return this.geo.point(lat, long);
   }

   getDistance(point1 : geofirex.FirePoint, point2: geofirex.FirePoint) {
    const distance = this.geo.distance(point1, point2) * 1000; // Distance en mètres

    if (distance < 1000) {
      return `${Math.round(distance)} m`; // Distance inférieure à 1000 mètres, sans virgule
    } else {
      return `${(distance / 1000).toFixed(2)} km`; // Distance en kilomètres avec 2 chiffres après la virgule
    }
    }



}
